<template>
    <div>
        
        <div class="wz">
            <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>大数据管理</el-breadcrumb-item>
            <el-breadcrumb-item>职位热度薪资排行榜</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="box">
            <div class="topchange">
                <el-input size="small" v-model="num" placeholder="请输入一个数字" style="width:200px;margin-right:5px;"  onkeyup="value=value.replace(/[^\d\.]/g,'')" ></el-input><el-button type="primary" size="small" @click="changedata">更改数据</el-button><el-button type="primary" size="small" @click="resetdata">重置数据</el-button>
            </div>
            <div class="list">
                <div class="listtitle"><strong>职位热度TOP10排行榜</strong><el-button type="primary" size="mini" style="float:right;" @click="openadd(1)">+添加记录</el-button></div>
                <div class="listitem">
                    <ul>
                        <li v-for="item in list1"><span>{{item.topName}}</span><em>{{item.topValue}}</em><p><i @click="editopen(item)">编辑</i><i @click="deleteitem(item)">删除</i></p></li>
                    </ul>
                </div>
            </div>
            <div class="list">
                <div class="listtitle"><strong>职位平均薪资TOP10排行榜</strong><el-button type="primary" size="mini" style="float:right;" @click="openadd(2)">+添加记录</el-button></div>
                <div class="listitem">
                    <ul>
                        <li v-for="item in list2"><span>{{item.topName}}</span><em>{{item.topValue}}</em><p><i @click="editopen(item)">编辑</i><i @click="deleteitem(item)">删除</i></p></li>
                    </ul>
                </div>
            </div>
            <div class="topchange">
                <el-input size="small" v-model="num2" placeholder="请输入一个数字" style="width:200px;margin-right:5px;"  onkeyup="value=value.replace(/[^\d\.]/g,'')" ></el-input><el-button type="primary" size="small" @click="changedata2">更改数据</el-button><el-button type="primary" size="small" @click="resetdata2">重置数据</el-button>
            </div>
            <div class="list">
                <div class="listtitle"><strong>高热度高薪资职位</strong><el-button type="primary" size="mini" style="float:right;" @click="openadd2(1)">+添加记录</el-button></div>
                <div class="listitem">
                    <ul>
                        <li v-for="item in list3"><span>{{item.rankingName}}</span> ------ 热度指数：<em>{{item.heatIndex}}</em> ------ 薪资指数：<em>{{item.salaryIndex}}</em><p><i @click="editopen2(item)">编辑</i><i @click="deleteitem2(item)">删除</i></p></li>
                    </ul>
                </div>
            </div>
            <div class="list">
                <div class="listtitle"><strong>高热度低薪资职位</strong><el-button type="primary" size="mini" style="float:right;" @click="openadd2(2)">+添加记录</el-button></div>
                <div class="listitem">
                    <ul>
                        <li v-for="item in list4"><span>{{item.rankingName}}</span> ------ 热度指数：<em>{{item.heatIndex}}</em> ------ 薪资指数：<em>{{item.salaryIndex}}</em><p><i @click="editopen2(item)">编辑</i><i @click="deleteitem2(item)">删除</i></p></li>
                    </ul>
                </div>
            </div>
            <div class="list">
                <div class="listtitle"><strong>低热度高薪资职位</strong><el-button type="primary" size="mini" style="float:right;" @click="openadd2(3)">+添加记录</el-button></div>
                <div class="listitem">
                    <ul>
                        <li v-for="item in list5"><span>{{item.rankingName}}</span> ------ 热度指数：<em>{{item.heatIndex}}</em> ------ 薪资指数：<em>{{item.salaryIndex}}</em><p><i @click="editopen2(item)">编辑</i><i @click="deleteitem2(item)">删除</i></p></li>
                    </ul>
                </div>
            </div>
            <div class="list">
                <div class="listtitle"><strong>低热度低薪资职位</strong><el-button type="primary" size="mini" style="float:right;" @click="openadd2(4)">+添加记录</el-button></div>
                <div class="listitem">
                    <ul>
                        <li v-for="item in list6"><span>{{item.rankingName}}</span> ------ 热度指数：<em>{{item.heatIndex}}</em> ------ 薪资指数：<em>{{item.salaryIndex}}</em><p><i @click="editopen2(item)">编辑</i><i @click="deleteitem2(item)">删除</i></p></li>
                    </ul>
                </div>
            </div>
        </div>
        <pop-up title="添加" width="26%" :isshow="addshow" :haveconfirm="true" @close="getclose" @confirm="getconfirm">
            <div slot="box">
                <el-form ref="addfrom" :model="addfrom" label-width="50px">
                    <el-form-item label="名称">
                        <el-input v-model="addfrom.topName"></el-input>
                    </el-form-item>
                    <el-form-item label="值">
                        <el-input v-model="addfrom.topValue"></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </pop-up>
        <pop-up title="编辑" width="26%" :isshow="editshow" :haveconfirm="true" @close="getclose2" @confirm="getconfirm2">
            <div slot="box">
                <el-form ref="addfrom" :model="editfrom" label-width="50px">
                    <el-form-item label="名称">
                        <el-input v-model="editfrom.topName"></el-input>
                    </el-form-item>
                    <el-form-item label="值">
                        <el-input v-model="editfrom.topValue"></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </pop-up>
        <pop-up title="添加" width="26%" :isshow="addshow2" :haveconfirm="true" @close="getclose3" @confirm="getconfirm3">
            <div slot="box">
                <el-form ref="addfrom" :model="addfrom2" label-width="80px">
                    <el-form-item label="职位名称">
                        <el-input v-model="addfrom2.rankingName"></el-input>
                    </el-form-item>
                    <el-form-item label="热度指数">
                        <el-input v-model="addfrom2.heatIndex"></el-input>
                    </el-form-item>
                    <el-form-item label="薪资指数">
                        <el-input v-model="addfrom2.salaryIndex"></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </pop-up>
        <pop-up title="编辑" width="26%" :isshow="editshow2" :haveconfirm="true" @close="getclose4" @confirm="getconfirm4">
            <div slot="box">
                <el-form ref="addfrom" :model="editfrom2" label-width="80px">
                    <el-form-item label="职位名称">
                        <el-input v-model="editfrom2.rankingName"></el-input>
                    </el-form-item>
                    <el-form-item label="热度指数">
                        <el-input v-model="editfrom2.heatIndex"></el-input>
                    </el-form-item>
                    <el-form-item label="薪资指数">
                        <el-input v-model="editfrom2.salaryIndex"></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </pop-up>
    </div>
</template>
<script>
import serverurl from '@/api/bigdata'
import _api from '@/api/index'
export default {
    data(){
        return{
            num:"",
            num2:"",
            addshow2:false,
            addshow:false,
            editshow:false,
            editshow2:false,
            personnel:[],
            addfrom:{
                topName:"",
                topValue:"",
                type:1,
            },
            editfrom:{
                id:"",
                topName:"",
                topValue:"",
                type:1,
            },
            addfrom2:{
                rankingName:"",
                heatIndex:"",
                salaryIndex:"",
                type:1,
            },
            editfrom2:{
                id:"",
                rankingName:"",
                heatIndex:"",
                salaryIndex:"",
                type:1,
            },
            list1:[],
            list2:[],
            list3:[],
            list4:[],
            list5:[],
            list6:[],
        }
    },
    created(){
        this.getList1()
        this.getList2()
        this.getList3()
        this.getList4()
        this.getList5()
        this.getList6()
    },
    methods:{
        resetdata(){
            _api.get(serverurl.reset5,{}).then(res=>{
                console.log(res)
                if(res.success){
                    this.$message.success("重置成功！")
                    this.getList1()
                    this.getList2()
                }
            })
        },
        changedata(){//更改数据
            if(this.num==""){
                this.$message.error("请输入一个变换系数")
                return
            }
            _api.get(serverurl.random5,{num:this.num}).then(res=>{
                console.log(res)
                if(res.success){
                    this.$message.success("更改成功！")
                    this.getList1()
                    this.getList2()
                }
            })
        },
        resetdata2(){
            _api.get(serverurl.reset6,{}).then(res=>{
                console.log(res)
                if(res.success){
                    this.$message.success("重置成功！")
                    this.getList3()
                    this.getList4()
                    this.getList5()
                }
            })
        },
        changedata2(){//更改数据
            if(this.num2==""){
                this.$message.error("请输入一个变换系数")
                return
            }
            _api.get(serverurl.random6,{num:this.num2}).then(res=>{
                console.log(res)
                if(res.success){
                    this.$message.success("更改成功！")
                    this.getList3()
                    this.getList4()
                    this.getList5()
                }
            })
        },
        deleteitem(row){
            _api.get(serverurl.toptendel,{id:row.id}).then(res=>{
                if(res.success){
                    this.$message.success("删除成功！")
                    if(row.type==1){
                        this.getList1()
                    }else{
                        this.getList2()
                    }
                }
            })
        },
        deleteitem2(row){
            _api.get(serverurl.rankdel,{id:row.id}).then(res=>{
                if(res.success){
                    this.$message.success("删除成功！")
                    if(row.type==1){
                        this.getList3()
                    }else if(row.type==2){
                        this.getList4()
                    }
                    else if(row.type==3){
                        this.getList5()
                    }else{
                        this.getList6()
                    }
                }
            })
        },
        editopen2(row){
            this.editshow2=true
            this.editfrom2.type=row.type
            this.editfrom2.id=row.id
            this.editfrom2.rankingName=row.rankingName
            this.editfrom2.heatIndex=row.heatIndex
            this.editfrom2.salaryIndex=row.salaryIndex
        },
        editopen(row){
            this.editshow=true
            this.editfrom.id=row.id
            this.editfrom.topName=row.topName
            this.editfrom.topValue=row.topValue
            this.editfrom.type=row.type
        },
        getList1(){
            _api.get(serverurl.toptenlist,{type:1}).then(res=>{
                if(res.success){
                    this.list1=res.data
                }
            })
        },
        getList2(){
            _api.get(serverurl.toptenlist,{type:2}).then(res=>{
                if(res.success){
                    this.list2=res.data
                    
                }
            })
        },
        getList3(){
            _api.get(serverurl.ranklist,{type:1}).then(res=>{
                if(res.success){
                    this.list3=res.data
                }
            })
        },
        getList4(){
            _api.get(serverurl.ranklist,{type:2}).then(res=>{
                if(res.success){
                    this.list4=res.data
                }
            })
        },
        getList5(){
            _api.get(serverurl.ranklist,{type:3}).then(res=>{
                if(res.success){
                    this.list5=res.data
                }
            })
        },
        getList6(){
            _api.get(serverurl.ranklist,{type:4}).then(res=>{
                if(res.success){
                    this.list6=res.data
                }
            })
        },
        openadd2(e){
            this.addshow2=true
            this.addfrom2.type=e
        },
        openadd(e){
            this.addfrom.type=e
            this.addshow=true
        },
        getclose4(){
            this.editshow2=false
        },
        getclose3(){
            this.addshow2=false
        },
        getclose2(){
            this.editshow=false
        },
        getclose(){
            this.addshow=false
        },
        getconfirm4(){
             _api.post(serverurl.rankadd,this.editfrom2).then(res=>{
                this.editshow2=false
                if(res.success){
                    this.$message.success("编辑成功！")
                    if(this.editfrom2.type==1){
                        this.getList3()
                    }else if(this.editfrom2.type==2){
                        this.getList4()
                    }
                    else if(this.editfrom2.type==3){
                        this.getList5()
                    }else{
                        this.getList6()
                    }
                }
            })
        },
        getconfirm3(){
            _api.post(serverurl.rankadd,this.addfrom2).then(res=>{
                this.addshow2=false
                console.log(res)
                if(res.success){
                    this.$message.success("添加成功！")
                    if(this.addfrom2.type==1){
                        this.getList3()
                    }else if(this.addfrom2.type==2){
                        this.getList4()
                    }
                    else if(this.addfrom2.type==3){
                        this.getList5()
                    }else{
                        this.getList6()
                    }
                }
            })
        },
        getconfirm2(){
             _api.post(serverurl.toptenadd,this.editfrom).then(res=>{
                this.editshow=false
                console.log(res)
                if(res.success){
                    this.$message.success("编辑成功！")
                    if(this.editfrom.type==1){
                        this.getList1()
                    }else{
                        this.getList2()
                    }
                }
            })
        },
        getconfirm(){
            _api.post(serverurl.toptenadd,this.addfrom).then(res=>{
                this.addshow=false
                console.log(res)
                if(res.success){
                    this.$message.success("添加成功！")
                    if(this.addfrom.type==1){
                        this.getList1()
                    }else{
                        this.getList2()
                    }
                }
            })
        }
    }
}
</script>
<style scoped>
@import "../../assets/css/bigdata.css";
.list{ overflow: hidden;}
.topchange{ text-align: center; padding-bottom: 20px;}
</style>