const API={
    bigType:"/personnel/list",//列表post
    insertAndEdit:"/personnel/insertAndEdit",//添加或编辑post
    deleteitem:"/personnel/delete",//添加或编辑post
    supplylist:"/supplyAndDemand/list",//供需关系列表get
    supplyinsert:"/supplyAndDemand/insert",//供需关系编辑post
    supplyinsert2:"/barChart/insert",//供需关系编辑2post
    supplylist2:"/barChart/list",//供需关系列表2get
    bigIndustry:"/bigIndustry/list",//产业供需分析列表
    Industryinsert:"/bigIndustry/insertAndEdit",//产业供需分析编辑
    industrydelete:"/bigIndustry/delete",//产业供需分析删除
    toptenadd:"/topTen/insertAndEdit",//职位热度薪资排行榜添加post
    toptenlist:"/topTen/list",//职位热度薪资排行榜列表get
    toptendel:"/topTen/del",//职位热度薪资排行榜列表get
    rankadd:"/ranking/insertAndEdit",//职位热度薪资排行榜下面4块添加post
    ranklist:"/ranking/list",//职位热度薪资排行榜下面4块列表get
    rankdel:"/ranking/del",//职位热度薪资排行榜下面4块删除get
    personadd:"/personnelSd/insertAndEdit",//上饶流动人才趋势分析添加post
    personlist:"/personnelSd/query",//上饶流动人才趋势分析列表get
    persondel:"/personnelSd/del",//上饶流动人才趋势分析删除get
    keyadd:"/keyIndustries/insertAndEdit",//意向流入流出添加
    keylist:"/keyIndustries/query",//意向流入流出添加
    keydel:"/keyIndustries/del",//意向流入流出删除
    talentadd:"/talentStatistics/insertAndEdit",
    talentlist:"/talentStatistics/query",
    talentdel:"/talentStatistics/del",
    random1:"/personnel/random",//第一张图随机变化
    reset1:"/personnel/reset",//第一张图重置
    random2:"/supplyAndDemand/random",//第二张一部分图重置
    // random2:"/supplyAndDemand/random",//第二张一部分图重置
    random3:"/barChart/random",
    reset3:"/barChart/reset",
    random4:"/bigIndustry/random",
    reset4:"/bigIndustry/reset",
    random5:"/topTen/random",
    reset5:"/topTen/reset",
    random6:"/ranking/random",
    reset6:"/ranking/reset",
    random7:"/randomReset/random",
    reset7:"/randomReset/reset",
    average:"/averageSalary/list",//园区平均工资水平列表
    averageinsert:"/averageSalary/insertAndEdit",//园区平均工资水平添加与编辑
    averagereset:"/averageSalary/reset",//重置
    averagerandom:"/averageSalary/random",//随机
    dailyData:"/dailyData/list",//
    dailyDatainsert:"/dailyData/insertAndEdit",//
    dailyDatareset:"/dailyData/reset",//
    dailyDatarandom:"/dailyData/random",//
    offlinejob:"/offline/job",//春风行动接口
    offlinejobdelete:"/offline/job/delete",//春风行动接口
    potionmember:"/potion/member",//重点企业大数据统计管理
    potionmemberdelete:"/potion/member/delete",//重点企业大数据统计管理
    

}

export default API